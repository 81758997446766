const config = {
  rootProjectId: "root",
  uiBucketName: "demo.c.retter.io",
  appUrl: "https://api.demo.retter.io/",
  cosUrl: "api.demo.retter.io",
  version: "2.1.14",
  captchaKey: "6LexLEspAAAAALtvD2Ep9gFDg8p-xaiOA-zkkhxx",
  stage: "PROD"
}
export default config

